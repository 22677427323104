.App {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .imgBackground{
      background-image: url('../images/Podcast.jpg');
      background-color: #cccccc; /* Used if the image is unavailable */
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
  }

  .main{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }

  section{
    position: relative;
    width: 80%;
    height: 80vh;
    /* width: 100%;
    height: 100vh; */
  }

  section iframe{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      margin-left: 200px ;
      /* width: 100%;
      height: 100%; */
      object-fit: cover;
  }

  section .navigation{
      /* position: absolute; */
      bottom: 90px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  section .navigation li {
      list-style: none;
      cursor: pointer;
      margin: 0 10px;
      border-radius: 4px;
      background: #fff;
      padding: 3px 3px 0;
      opacity: 0.7;
      transition: 0.5s;
  }

  section .navigation li:hover{
      opacity: 1;
  }

  section .navigation li img{
      width: 120px;
      transition: 0.5s;
  }

  section .navigation li img:hover{
      width: 200px;
  }

  ul{
    position: relative;
    margin-top: 450px !important;
    margin-bottom: 0px !important;
  }

@media(max-width: 1000px) { 
    section iframe{
        margin-left: 0px;
    }
}