@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* base styles */
* {
  margin: 0;
  color: #333;
}

/* body {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 10px;
  background: whitesmoke;
  font-family: Arial, sans-serif;
  align-self: center;
} */

/* .navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
} */
.content {
  width: 100vw;
  height: 100vh;
  margin: auto;
  align-items: center;
  vertical-align: middle;
  /* padding: 20px 20px; */
}

/* tik-tak-toe */

.square {
  /* width: 15vw;
  height: 15vw; */
  width: calc(100% / 3);
  border-left: none;

  margin: 2.5px;
  background-color: #000c40;
  border: none;
  font-weight: 900;
  font-size: 80px;
  color: aliceblue;
  /* float: left; */
}

.square:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  background: lightblue;
  cursor: pointer;
}

.square:active {
  background: rgb(50, 105, 168);
}

.game {
  /* text-align: center; */
  /* margin: auto; */

  height: 80vmin;
  width: 80vmin;
  box-shadow: 1px 3px 12px 3px lightgrey;
  margin: auto;
  margin-top: 7vh;
}

.game-board {
  /* display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center; */
  width: 100%;
  height: 100%;
  /* padding-top: 50px; */
  /* margin: auto; */
}

.next-player {
  position: fixed;
  top: 0%;
  border-radius: 3px;
  width: 60vmin;
  height: 30vmin;
  background: rgba(255, 255, 255, 0.9);
  animation: modal 400ms forwards;
  /* display: none; */
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
  box-shadow: 1px 1px 1px 1px lightgrey;
  text-align: center;
  font-size: 2.8vh;
  line-height: 4.5vh;
  padding-top: 1%;
  margin: 10% 6%;
  padding: auto;
}

.next-player > p {
  font-size: 30px;
  font-weight: 900;
}

.controller-btns {
  display: flex;
}

.controller-btns button:hover {
  box-shadow: 1px 3px 5px #f1356d;
  background: #f1356d;
  font-weight: bold;
}

/* .copyrights{
  text-align: center;
  position: fixed;
  bottom: 20px;
  width: 100vw;
  right: 20px;
}

.copyrights a{
  text-decoration: none;
  font-weight: 500;
}

.copyrights a:hover{
  box-shadow: 1px 3px 5px rgba(0, 0, 0, .1);
} */

.one-row {
  display: flex;
  justify-content: space-around;
  align-content: center;
  vertical-align: middle;
  height: calc(100% / 3);
}

.undoButton {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  background-color: #ff5733;
  color: #ffffff !important;
  width: 150px;
  height: 50px;
  margin: 0 auto;
  margin-top: 30px;
  align-self: center;
}

.chicken {
  width: 70px;
  height: 70px;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  vertical-align: text-top;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.startBoard {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000c40;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.startBoard > span {
  font-size : 40px;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 40px;
}

.startButtons {
 display: flex;
 width: 300px;
 justify-content: space-between;
}
.startButton {
  width: 100px;
  height: 100px;
}
.startButton > img {
  width: 100px;
  height: 100px;
}

.okButton {
  width: 80px;
  height: 30px;
  color: #ffffff;
  background-color: rgb(11, 243, 127);
  cursor: pointer;
  border: none;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 900;
}

.navbarNew{
  justify-content: space-between !important;
}